import { inject, observer } from 'mobx-react'
import { ApplicationStore, UserStore } from '../../stores'
import { ActionDropdown, ActionDropdownMenuOption } from '@mobilizeyourtech/vision-core-react'
import React, { useState } from 'react'
import { CContainer, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react'
import { ChangeEmailForm, ChangePasswordForm, EditAccountForm, EditProfileForm } from '../form'
import { hasIgniteRole } from '../../stores/UserStore'

export interface AccountActionsProps {
  userStore?: UserStore
}

export const AccountActions = inject(ApplicationStore.names.userStore)(
  observer((props: AccountActionsProps) => {
    const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState<boolean>(false)
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false)
    const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState<boolean>(false)
    const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState<boolean>(false)

    const onActionSelect = (o: ActionDropdownMenuOption) => {
      switch (o.id) {
        case 0:
          setIsEditProfileModalOpen(true)
          break
        case 1:
          setIsChangeEmailModalOpen(true)
          break
        case 2:
          setIsChangePasswordModalOpen(true)
          break
        case 3:
          setIsEditAccountModalOpen(true)
          break
      }
    }

    const options = React.useMemo(() => {
      let ret = [
        { id: 0, name: 'Edit Profile', iconClassName: 'fas fa-pencil-alt' },
        { id: 1, name: 'Change Email', iconClassName: 'fas fa-edit' },
        { id: 2, name: 'Change Password', iconClassName: 'fas fa-user-secret' },
      ]

      if (hasIgniteRole(props.userStore!.currentUserData!)) {
        ret = [...ret, { id: 3, name: 'Edit Account', iconClassName: 'fas fa-pen' }]
      }

      return ret
    }, [])

    return (
      <div className={'AccountActions'}>
        <ActionDropdown
          dropdownOptions={{ text: undefined, iconClassName: 'fa fa-bars' }}
          menuOptions={options}
          onSelect={onActionSelect}
        />

        <CModal
          size="xl"
          alignment="center"
          visible={isEditProfileModalOpen}
          onClose={() => setIsEditProfileModalOpen(false)}
        >
          <CModalHeader>
            <CModalTitle>Edit Profile</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <EditProfileForm onSubmitted={() => setIsEditProfileModalOpen(false)} />
            </CContainer>
          </CModalBody>
        </CModal>

        <CModal
          size="lg"
          alignment="center"
          visible={isChangePasswordModalOpen}
          onClose={() => setIsChangePasswordModalOpen(false)}
        >
          <CModalHeader>
            <CModalTitle>Change Password</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <ChangePasswordForm onSubmitted={() => setIsChangePasswordModalOpen(false)} />
            </CContainer>
          </CModalBody>
        </CModal>

        <CModal
          size="lg"
          alignment="center"
          visible={isChangeEmailModalOpen}
          onClose={() => setIsChangeEmailModalOpen(false)}
        >
          <CModalHeader>
            <CModalTitle>Change Email</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <ChangeEmailForm onSubmitted={() => setIsChangeEmailModalOpen(false)} />
          </CModalBody>
        </CModal>

        <CModal
          size="lg"
          alignment="center"
          visible={isEditAccountModalOpen}
          onClose={() => setIsEditAccountModalOpen(false)}
        >
          <CModalHeader>
            <CModalTitle>Edit Account</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <EditAccountForm onSubmitted={() => setIsEditAccountModalOpen(false)} />
            </CContainer>
          </CModalBody>
        </CModal>
      </div>
    )
  }),
)
