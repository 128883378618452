import axios from 'axios'
import { AuthStore, NotificationStore, UserData } from './'
import {
  CountResponse,
  Data,
  PaginatedSearchableParams,
  PaginationParams,
  SearchableParams,
  ServerPageData,
} from '../lib/types/Params'
import { IBusinessClassifications, INaicsCodes } from '../components/form/fragments/listing'
import { Location } from 'history'

import { extractRestfulError, VisionRequestInterceptedError } from '../lib/errors/utils'
import { AccountData } from './UserStore'
import {
  GenericLabeledItemResponse,
  NamedServerArtifact,
  NamedWithLabelServerArtifact,
  RestfulMessage,
  SubPageData,
} from '../lib/types'
import { setRedirectSessionVariables } from '../lib/helpers/sessionHelpers'

export type SocialMediaTypeResponse = {
  id: number
  name: string
}

export interface GovUserClassificationCategory {
  id: number
  name: string
}

export interface GovUserClassification {
  id: number
  name: string
  description: string
  createdAt: string
  updatedAt: string
  govUserClassificationCategoryId: number
  govUserClassificationCategory?: GovUserClassificationCategory
}

export type ChatMessageResponse = {
  id: number
  userId: number
  chatThreadId: number
  content: string
  user: UserData
  createdAt: string
  updatedAt: string
}

export interface ChatParticipantIdentifiers {
  participantType: string
  participantId: number
}

export interface ChatParticipantResponse extends ChatParticipantIdentifiers {
  chatThreadId: number
  participant: UserData | AccountData
}

export const isAccountData = (data: UserData | AccountData): data is AccountData => {
  return 'name' in data
}

export const isUserParticipant = (participant: ChatParticipantResponse) =>
  participant.participantType === 'User'
export const isAccountParticipant = (participant: ChatParticipantResponse) =>
  participant.participantType === 'Account'

export type ChatThreadResponse = {
  id: number
  participants: Array<ChatParticipantResponse>
  currentUserUnreadCount: number
  userChatMessages: SubPageData<ChatMessageResponse> | string
  lastMessageSeenAt: string
  createdAt: string
  status: string
}

export interface GovUserOrganizationResponse extends NamedWithLabelServerArtifact {
  shortName?: string
  longName?: string
}

export interface GovUserProgramOfficeResponse extends NamedWithLabelServerArtifact {
  shortName?: string
  longName?: string
}

export interface TechnologyTypeResponse extends NamedServerArtifact {
  isDeprecated: boolean
}

export class ServerStore {
  protected authStore: AuthStore
  protected notificationStore: NotificationStore

  constructor(authStore: AuthStore, notificationStore: NotificationStore) {
    this.authStore = authStore
    this.notificationStore = notificationStore
  }

  public server() {
    const instance = axios.create({
      headers: {
        'Cache-Control': 'no-store, no cache, must-revalidate',
        Authorization: this.authStore.getJWT() || '',
        Accept: 'application/json',
      },
    })
    // Leaving this comment here,
    // this is a handy debug tool to use if some network error is occurring in a test due to lack of mock
    // and we need to track it down
    // instance.interceptors.response.use(undefined, function (error) {
    //   const bp = true
    //   console.log(error)
    // })

    instance.interceptors.response.use((response) => response, this.errorInterceptor)
    return instance
  }

  public getNaicsCodes(
    params: PaginatedSearchableParams | {} = {},
  ): Promise<ServerPageData<INaicsCodes>> {
    return this.server()
      .get('/api/v1/naics_codes', {
        params,
      })
      .then(({ data }) => data as ServerPageData<INaicsCodes>)
  }

  public getBusinessClassifications(
    params: SearchableParams,
  ): Promise<Data<IBusinessClassifications>> {
    return this.server()
      .get('/api/v1/business_classifications', {
        params,
      })
      .then((response) => response as Data<IBusinessClassifications>)
  }

  public getTechnologyTypes(
    params: PaginatedSearchableParams & { ids?: string },
  ): Promise<ServerPageData<TechnologyTypeResponse>> {
    return this.server()
      .get('/api/v1/technology_types', {
        params,
      })
      .then(({ data }) => data as ServerPageData<TechnologyTypeResponse>)
  }

  public getAwardsPhases = async (
    params: PaginationParams,
  ): Promise<ServerPageData<GenericLabeledItemResponse>> => {
    return this.server()
      .get('/api/v1/phases', { params })
      .then(({ data }) => data)
  }

  public getChatThreadMessages(
    id: number,
    params: PaginationParams,
  ): Promise<ServerPageData<ChatMessageResponse>> {
    return this.server()
      .get(`/api/v1/chat_threads/${id.toString()}/user_chat_messages`, { params: params })
      .then(({ data }) => data)
  }

  public getChatThread(id: number, eager?: string): Promise<ChatThreadResponse> {
    return this.server()
      .get(`/api/v1/chat_threads/${id}`, { params: { eager } })
      .then(({ data }) => data)
  }

  public getChatThreads({
    params,
    eager,
    sortThreadId,
  }: {
    params: PaginationParams
    eager?: string
    sortThreadId?: number
  }): Promise<ServerPageData<ChatThreadResponse>> {
    return this.server()
      .get(`/api/v1/current_user/chat/threads`, {
        params: {
          page: params.page,
          perPage: params.perPage,
          eager: eager,
          sortThreadId: sortThreadId,
        },
      })
      .then(({ data }) => data as ServerPageData<ChatThreadResponse>)
  }

  public pauseChatThread(id: number): Promise<ChatThreadResponse> {
    return this.server()
      .patch(`/api/v1/chat_threads/${id}/pause`)
      .then(({ data }) => data)
  }

  public unpauseChatThread(id: number): Promise<ChatThreadResponse> {
    return this.server()
      .patch(`/api/v1/chat_threads/${id}/unpause`)
      .then(({ data }) => data)
  }

  public findChatThreadByParticipants(
    participants: Array<ChatParticipantIdentifiers>,
  ): Promise<ChatThreadResponse | undefined> {
    return this.server()
      .post(`/api/v1/chat_threads/find`, { participants })
      .then(({ data }) => data)
  }

  public createChatThread(
    participants: Array<ChatParticipantIdentifiers>,
    initialMessage: string,
  ): Promise<ChatThreadResponse> {
    return this.server()
      .post(`/api/v1/chat_threads`, {
        participants,
        initialMessages: [{ content: initialMessage }],
      })
      .then(({ data }) => data)
  }

  public getUnreadMessagesCount(): Promise<CountResponse> {
    return this.server()
      .get('/api/v1/current_user/chat/unread_messages_count')
      .then(({ data }) => data)
  }

  public getSocialMediaTypes(): Promise<Array<SocialMediaTypeResponse>> {
    return this.server()
      .get<Array<SocialMediaTypeResponse>>('/api/v1/social_media_types')
      .then(({ data }) => data)
  }

  public getGovUserOrganizations(
    params: PaginatedSearchableParams,
  ): Promise<ServerPageData<GovUserOrganizationResponse>> {
    return this.server()
      .get('/api/v1/gov_user_organizations', {
        params,
      })
      .then(({ data }) => data as ServerPageData<GovUserOrganizationResponse>)
  }

  public getGovUserProgramOffices = (
    params: PaginatedSearchableParams,
  ): Promise<ServerPageData<GovUserProgramOfficeResponse>> => {
    return this.server()
      .get('/api/v1/gov_user_program_offices', {
        params,
      })
      .then(({ data }) => data as ServerPageData<GovUserProgramOfficeResponse>)
  }

  public getGovUserClassifications = async (
    params: PaginatedSearchableParams & { eager?: string; ids?: string },
  ): Promise<ServerPageData<GovUserClassification>> => {
    return this.server()
      .get('/api/v1/gov/user_classifications', {
        params: { ...params },
      })
      .then(({ data }) => data as ServerPageData<GovUserClassification>)
  }

  public checkUniqueEntity(uniqueEntityId: string): Promise<any | undefined> {
    return this.server()
      .get<RestfulMessage>('/api/v1/accounts/check_unique_entity', { params: { uniqueEntityId } })
      .then((res) => {
        if (res.status === 204) {
          return undefined
        }
        return res.data
      })
  }

  public errorInterceptor = async (error: any) => {
    const currentLocation = {
      pathname: window.location.pathname,
      search: window.location.search,
    } as Location

    if (error.response?.status === 401) {
      const err = extractRestfulError(error)
      if (err) {
        // Our custom error response format
        switch (err.error) {
          case 'ExpiredToken':
            setRedirectSessionVariables(currentLocation)
            this.forceLogoutWithMessage('Your session has expired, please sign in again.')
            throw new VisionRequestInterceptedError(err.error)

          case 'InvalidToken':
          case 'MissingToken':
          case 'DeviseGeneral':
            setRedirectSessionVariables(currentLocation)
            this.forceLogoutWithMessage('Please sign in.')
            throw new VisionRequestInterceptedError(err.error)

          case 'UnconfirmedEmail':
            if (this.authStore.JWT) {
              this.redirectWithMessage('/confirmation_instructions', 'Please confirm your email.', [
                '/confirm_email',
              ])
            } else if (error.config.headers['X-User-Email']) {
              let buff = Buffer.from(error.config.headers['X-User-Email'])
              let base64data = buff.toString('base64')

              this.redirectWithMessage(
                `/confirmation_instructions?key=${base64data}`,
                'Please confirm your email.',
                ['/confirm_email'],
              )
            } else {
              this.redirectWithMessage('/confirmation_instructions', 'Please confirm your email.', [
                '/confirm_email',
              ])
            }
            throw new VisionRequestInterceptedError(err.error)

          case 'MissingTermsPrivacy':
            setRedirectSessionVariables(currentLocation)
            this.redirectWithMessage('/sign_terms', undefined)
            throw new VisionRequestInterceptedError(err.error)

          default:
            break
        }
      }
    }
    throw error
  }

  private forceLogoutWithMessage = (message: string) => {
    this.authStore.setJWT(null)
    this.notificationStore.setNotificationMessage(message, 'info', 3000, true)
  }

  private redirectWithMessage = (
    path: string,
    message: string | undefined,
    unlessPaths?: string[],
  ) => {
    if (
      window.location.pathname !== path &&
      (unlessPaths ? unlessPaths?.every((p) => p !== window.location.pathname) : true)
    ) {
      window.location.replace(path)
      this.notificationStore.setNotificationMessage(message, 'info', 3000, true)
    }
  }
}
