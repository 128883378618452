import { Chip, ChipColors, ChipProps } from '@mobilizeyourtech/vision-core-react'
import classNames from 'classnames'

import Styles from './GovUserConnectorChip.module.scss'
import { GovUserData } from 'stores/UserStore'

export interface GovUserConnectorChipProps
  extends Omit<ChipProps, 'label' | 'color' | 'badgeValue' | 'chipBackground' | 'icon' | 'size'> {
  govUser: GovUserData | undefined
  'data-testid'?: string
}

export const GovUserConnectorChip = ({ govUser, ...props }: GovUserConnectorChipProps) => {
  if (!govUser?.activeChatThreadsCount) return null

  let label = 'Connector'
  let badge = '1+'

  if (govUser.activeChatThreadsCount >= 5 && govUser.activeChatThreadsCount < 10) {
    label = 'Super Connector'
    badge = '5+'
  } else if (govUser.activeChatThreadsCount >= 10 && govUser.activeChatThreadsCount < 25) {
    label = 'Mega Connector'
    badge = '10+'
  } else if (govUser.activeChatThreadsCount >= 25 && govUser.activeChatThreadsCount < 50) {
    label = 'Ultra Connector'
    badge = '25+'
  } else if (govUser.activeChatThreadsCount >= 50) {
    label = 'Giga Connector'
    badge = '50+'
  }

  return (
    <Chip
      {...props}
      className={classNames(props.className, Styles.govUserConnectorChip)}
      icon="fa fa-bolt"
      label={label}
      badgeValue={badge}
      chipColor={ChipColors.BLUE}
      chipBackground
      chipSize="small"
      data-testid={props['data-testid'] ?? 'gov-user-connector-chip'}
      // FIXME: Update to use VCR's Tooltip component when available
      title={`${govUser.firstName} has ${govUser.activeChatThreadsCount} active connection(s) with small businesses`}
      displayTitle={false}
    />
  )
}
