import { inject, observer } from 'mobx-react'
import { CBadge, CCardText } from '@coreui/react'
import {
  ApplicationStore,
  AuthStore,
  NotificationStore,
  ServerStore,
  UserData,
  UserStore,
} from '../../stores'

import {
  ChatMessageResponse,
  ChatParticipantResponse,
  ChatThreadResponse,
  isAccountData,
  isAccountParticipant,
  isUserParticipant,
} from '../../stores/ServerStore'
import { UserAvatar } from '../user'
import { AccountData } from '../../stores/UserStore'
import { SubPageData } from '../../lib/types'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'

export const makeChatThreadHeadliner = (participantList: Array<ChatParticipantResponse>) =>
  participantList.map((item: ChatParticipantResponse, index: number) => {
    return (
      <Fragment key={index}>
        {index > 0 && index <= participantList.length - 1 && ', '}
        {isAccountData(item.participant) ? (
          <Link
            className="clickable"
            to={`/ignite/listings/${item.participant.defaultListingId}`}
            target="_blank"
          >
            {item.participant.name}
          </Link>
        ) : (
          <Link className="clickable" to={`/gov/users/${item.participant.id}`} target="_blank">
            {item.participant.firstName + ' ' + item.participant.lastName}
          </Link>
        )}
      </Fragment>
    )
  })

export type ChatThreadCardProps = {
  value: ChatThreadResponse
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  serverStore?: ServerStore
  selected?: boolean
  onSelect: () => void
}

export const ChatThreadCard = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
  ApplicationStore.names.serverStore,
)(
  observer((props: ChatThreadCardProps) => {
    const chatThreadData = props.value
    const user = props.userStore?.currentUserData
    const currentIsGovUser = props.userStore?.currentUserData?.userRoles.includes('gov')

    const displayParticipantList = chatThreadData.participants.filter(
      (participant: ChatParticipantResponse) => {
        if (isAccountParticipant(participant) && !currentIsGovUser) {
          return user?.accountId !== participant.participantId
        } else if (isAccountParticipant(participant) && currentIsGovUser) {
          return true
        } else {
          return user?.id !== participant.participantId
        }
      },
    )

    // set which user or account will be the featured first participant
    const featuredParticipant = () => {
      return displayParticipantList[0]
    }

    const mostRecentSenderName = () => {
      return (
        (chatThreadData.userChatMessages as SubPageData<ChatMessageResponse>).data[0].user
          .firstName +
        ' ' +
        (chatThreadData.userChatMessages as SubPageData<ChatMessageResponse>).data[0].user.lastName
      )
    }

    const renderBadge = () => {
      if (chatThreadData.currentUserUnreadCount > 999) {
        return <CBadge>{'1K+'}</CBadge>
      } else if (chatThreadData.currentUserUnreadCount > 0) {
        return <CBadge>{chatThreadData.currentUserUnreadCount}</CBadge>
      } else {
        return
      }
    }

    const renderFeaturedPhoto = () => {
      if (isUserParticipant(featuredParticipant())) {
        let featured = featuredParticipant()?.participant as UserData
        return <UserAvatar user={featured} displayIsActive />
      } else {
        // Accounts will only display 1 initial.
        let featured = featuredParticipant()?.participant as AccountData
        return <UserAvatar user={featured} />
      }
    }

    const onSelected = () => {
      props.onSelect()
    }

    return (
      <div
        className={`chat-thread-card${
          props.selected ? ' selected ' : ' '
        }w-100 p-3 d-flex flex-row gap-2`}
        onClick={() => onSelected()}
        data-testid={`chat-thread-card-${chatThreadData.id}`}
      >
        {renderFeaturedPhoto()}
        <div className="chat-thread-card-text">
          <div className="chat-thread-card-header-text d-flex gap-3 align-items-start justify-content-start">
            <h6 className="chat-thread-user-name">
              {makeChatThreadHeadliner(displayParticipantList)}
            </h6>
          </div>
          <CCardText className="most-recent-chat-text subtle">
            {user?.id ===
            (chatThreadData.userChatMessages as SubPageData<ChatMessageResponse>).data[0].userId
              ? 'You: '
              : `${mostRecentSenderName()}: `}
            {(chatThreadData.userChatMessages as SubPageData<ChatMessageResponse>).data[0].content}
          </CCardText>
        </div>
        <div className={'badge-frame'}>{renderBadge()}</div>
      </div>
    )
  }),
)

export default ChatThreadCard
