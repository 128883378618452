import { CCard } from '@coreui/react'
import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { AnalyticsStore, ApplicationStore, NotificationStore } from '../../../stores'
import { TechnologyTypeStatistic } from '../../../stores/AnalyticsStore'

import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import { AdminAnalyticsTechnologyTypePieCharts } from './pieCharts/AdminAnalyticsTechnologyTypePieCharts'
import { AdminAnalyticsTechnologyTypeTable } from './tables/AdminAnalyticsTechnologyTypeTable'

type TProps = {
  analyticsStore?: AnalyticsStore
  notificationStore?: NotificationStore
}

export const AdminAnalyticsTechnologyTypeStatistics = inject(
  ApplicationStore.names.analyticsStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [technologyTypeStatistics, setTechnologyTypeStatistics] = useState<
      Array<TechnologyTypeStatistic>
    >([])

    useEffect(() => {
      setIsLoading(true)
      props
        .analyticsStore!.getTechnologyTypeStatistics()
        .then((data) => {
          setTechnologyTypeStatistics(data)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Error loading Technology Type Statistic Data',
            'error',
            3000,
          )
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 300)
        })
    }, [])

    return (
      <div
        id="admin-analytics-technology-type-statistics-container"
        className="user-card-container d-flex flex-wrap w-100 align-items-center justify-content-center"
      >
        <CCard className="card-body">
          <SuspensionBoundary isLoading={isLoading}>
            <AdminAnalyticsTechnologyTypePieCharts data={technologyTypeStatistics} />
            <AdminAnalyticsTechnologyTypeTable data={technologyTypeStatistics} />
          </SuspensionBoundary>
        </CCard>
      </div>
    )
  }),
)
