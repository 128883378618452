import { CCard, CCardHeader, CCardBody, CBadge, CCol, CRow } from '@coreui/react'
import { CCardProps } from '@coreui/react/dist/components/card/CCard'
import classNames from 'classnames'
import { GovUserClassification } from 'stores/ServerStore'
import Styles from './GovUserClassificationsCard.module.scss'

export interface GovUserClassificationsCardProps extends CCardProps {
  govUserClassifications?: Array<GovUserClassification>
}

export const GovUserClassificationsCard = ({
  govUserClassifications = [],
  className,
  ...props
}: GovUserClassificationsCardProps) => {
  const govUserClassificationsSection = () => {
    if (govUserClassifications.length === 0) {
      return <span className="subtle">Nothing to show</span>
    }

    return (
      <CRow className="justify-content-md-start pt-2">
        {govUserClassifications.map((classification) => {
          return (
            <CCol key={classification.id} className={'col-md-auto px-1'}>
              <CBadge
                color="primary"
                title={classification.description}
                className={Styles.classificationBadge}
              >
                {classification.name}
              </CBadge>
            </CCol>
          )
        })}
      </CRow>
    )
  }

  return (
    <CCard className={classNames(className, Styles.GovUserClassificationsCard)} {...props}>
      <CCardHeader component="h5">Desired Interactions with Small Businesses</CCardHeader>
      <CCardBody>{govUserClassificationsSection()}</CCardBody>
    </CCard>
  )
}
