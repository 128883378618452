import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import { SuspensionBoundary, ServerPageData } from '@mobilizeyourtech/vision-core-react'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { NumberParam, useQueryParams, withDefault } from 'use-query-params'
import { Pagination } from '../../components/custom'
import { UserDetailCard, CreateModal } from '../../components/user'
import { PaginationParams } from '../../lib/types/Params'
import {
  AccountStore,
  ApplicationStore,
  NotificationStore,
  UserData,
  UserStore,
} from '../../stores'
import { InvitedUserData } from '../../stores/UserStore'

type TProps = {
  notificationStore?: NotificationStore
  userStore?: UserStore
  accountStore?: AccountStore
}

const Users = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.accountStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [users, setUsers] = useState<ServerPageData<UserData | InvitedUserData> | undefined>(
      undefined,
    )
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    const [params] = useQueryParams({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 10),
    })

    const currentAccountId = props.userStore!.currentAccountData!.id
    const { page, perPage } = params

    const getUserData = (p: PaginationParams) => {
      setIsLoading(true)

      props.accountStore
        ?.getAccountUsers(currentAccountId, p.page, p.perPage, true)
        .then((data) => {
          setUsers(data)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Unable to get user data, please try again later',
            'danger',
            3000,
          )
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 300)
        })
    }

    useEffect(() => {
      getUserData(params)
    }, [page, perPage])

    return (
      <div className="Users">
        <CreateModal
          data-testid="invite-user-modal"
          isModalOpen={isModalOpen}
          closeModal={() => {
            setIsModalOpen(false)
            getUserData(params)
          }}
        />
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="d-block py-2 px-4">Users</h2>
          <CButton
            data-testid="invite-button"
            className="invite-icon-button"
            onClick={() => {
              setIsModalOpen(true)
            }}
          >
            <i className="fa fa-plus invite-icon" />
            <h6>Invite User</h6>
          </CButton>
        </div>
        <CContainer className="user-card-container d-flex flex-wrap w-100 align-items-center justify-content-center">
          <SuspensionBoundary isLoading={isLoading}>
            <CRow className="justify-content-center">
              {users?.data.map((user: UserData | InvitedUserData) => (
                <CCol className="user-card-wrapper" lg={3} key={user.email}>
                  <UserDetailCard
                    key={user.email}
                    user={user}
                    currentAccountId={currentAccountId}
                    onSubmit={() => getUserData(params)}
                  />
                </CCol>
              ))}
            </CRow>
          </SuspensionBoundary>
        </CContainer>
        {users?.pagination && <Pagination pagination={users.pagination} defaultPerPage={10} />}
      </div>
    )
  }),
)

export default Users
